<template>
  <div style="position: relative;width: 100%;height: 100%;">
    <hotel-map
        v-if="eventMapFix"
        :event="eventMapFix"
        :hotelList="[]"
        :partnerHotels="partnerHotelsPrepared"
        :poi="poiPrepared"
        :covidBanner="false"
        :displayPrice="budgetType"
        style="position: relative;width: 100%;height: 100%;"
        :mapZoom="mapZoom"
        :settings="settings"
        :shareId="shareId"
        :shareType="shareType"
        :client="client"

        @selectMapHotel="selectMapHotel"
        @changeLocation="load"
        v-on:map-moveend="mapMoveEnd"
    />
  </div>
</template>

<script>

import hotelFilter from "@/function/hotel/hotelFilter";
import getNightCount from "@/function/hotel/getNightCount";
import partnerHotelsPrepare from "@/function/hotel/partnerHotelsPrepare";
import poiPrepare from "@/function/event/poiPrepere";

//import hotelMap             from "@/component/map/hotelMap";
import hotelMap from "@/component/mapV2/hotelMapV2";
// import loadingBar from "@/component/element/loadingBar";

// mixins
import eventLoad from "./eventLoader/eventLoadByWidget";
import hotelListLoader from "./hotelListLoader/hotelListLoadByCoordinateBooking";
import Client from "@client";

export default {

  mixins: [
    eventLoad,
    hotelListLoader,
  ],

  components: {
    hotelMap,
    // loadingBar,
  },
  props: {
    showMainEvent: {
      type: [Boolean, String],
      default: false
    },
    shareId : {
      type: [String, Number],
      default: undefined
    },
    shareType : {
      type: [String],
      default: undefined
    },
  },
  data() {
    return {
      eventMapFix: false, // fix рассинхрона запрашиваемого евента и статичного
      client: Client,
      settings: {
        labelSettings: CONST.labelSettings[Language.getName()],

        hasChange: false,

        // guests and rooms
        guestsAndRooms: false,
        adults: 1,
        child: 0,
        rooms: 1,

        // dates in moment
        dates: false,
        checkIn: null,
        checkOut: null,

        // filter
        filter: false,
        budget: [0, +Infinity],
        displayPrice: [
          {
            name: this.$t('map:Per night'),
            key: 'Per night',
            active: true
          },
          {
            name: this.$t('map:Total'),
            key: 'Total',
            active: false
          }
        ],
        roomType: [
          {
            name: this.$t('map:Hotels'),
            key: 'Hotels',
            active: true
          },
          {
            name: this.$t('map:Hostels'),
            key: 'Hostels',
            active: false
          }
        ],
        hotelStars: [3, 4], // [1, 2, 3]
        rating: 8,
        cancelation: false,

        showCallToActions: true,
        lang: Language.getName()
      },
    }
  },
  methods: {
    load() {
      this.eventLoad().then((event) => {
        this.eventMapFix = event;
        this.eventMapFix.name = this.$t('map:You are here!')
        this.eventMapFix.isShowing = this.showMainEvent
        // sync fix - может быть рассинхрон с хранилищем... нельзя присваивать this.eventData = event так как computed
        // при тестировании не удалось получить рассинхрон
        // this.eventData = event;
        this.tripSettingsInit();
        // this.hotelReload();
        // this.venueReload();
      });
    },
    tripSettingsInit() {
      this.settings.checkIn = this.eventData.tripDateStart.clone();
      this.settings.checkOut = this.eventData.tripDateEnd.clone();
    },

    mapMoveEnd(data) {
      this.mapCenter = data.center;
      this.mapRadius = data.radius;

      // this.hotelReload();
    },
    setSetting({type, value}) {
      if (type in this.settings) {
        this.settings[type] = value;
        this.settings.hasChange = true;
      }
    },

    updateHotelFilterCache() {
      this.hotelListCache = this.hotelListDataFiltered;
    },
    updateTripSettings(type) {
      if (type === 'filter') {
        this.updateHotelFilterCache();
      }
      if (type === 'dates' || type === 'travelers') {
        // eslint-disable-next-line no-undef
        VueStore.dispatch('hotelList_clearAll', name);
        //this.hotelReload();
        // this.venueReload();
      }

    },

    // venueReload() {
    //   if (!this.eventData) {
    //     return;
    //   }
    //   this.venueListRequestData = this.getVenueLoadRequest(this.eventData);
    //   this.venueListLoad();
    //
    // },

    hotelReload() {
      this.hotelListRequestData = this.getHotelLoadRequest();
      this.updateHotelFilterCache();

      if (!this.eventData) {
        return;
      }
      // this.hotelListRequestData = this.getHotelListRequestDataByEvent( this.eventData );

      // TODO test fix
      if (!this.mapCenter) {
        console.debug('NOT mapCenter');
        return;
      }
      if (this.hotelListRequestData.request.radius > 100) {
        console.debug('RADIUS > 100');
        return;
      }
      if (this.hotelListRequestData.request.radius > 50) {
        this.hotelListRequestData.request.radius = 50;
      }

      //
      this.hotelListLoad();
    },

    getHotelLoadRequest() {
      let prepare = {
        travelerCount: this.settings.adults + this.settings.child,
        total_hotel: 100,
        nightsInDest: getNightCount(this.settings.checkIn, this.settings.checkOut),
        event: this.eventData,
      };

      let childrenArr = []
      for (let i = 0; i < this.settings.child; i++) {
        childrenArr.push(9)
      }
      let request = {
        adults: this.settings.adults,
        children: childrenArr,
        city: this.eventData.venue.city.id,  // ????
        start_date: this.settings.checkIn.clone().locale('en').format('YYYY-MM-DD'),
        end_date: this.settings.checkOut.clone().locale('en').format('YYYY-MM-DD'),
        language: this.language,
        currency: this.currency,
      }

      if (this.mapCenter) {
        let radius = parseInt(this.mapRadius / 1000);

        // request.latitude  = this.mapCenter.lat;
        // request.longitude = this.mapCenter.lng;
        // radius = radius > 50 ? 50 : radius;
        // radius = radius < 1 ? 1 : radius;
        // request.latitude  = this.mapCenter.lat;
        // request.longitude = this.mapCenter.lng;
        // request.radius    = radius;

        // Test start
        request.latitude = this.mapCenter.lat;
        request.longitude = this.mapCenter.lng;


        radius = radius < 1 ? 1 : (parseInt(radius) + 1);
        // switch (true) {
        //   case (radius <= 5 ): radius = 5 ; break;
        //   case (radius <= 10): radius = 10; break;
        //   case (radius <= 25) : radius = 25; break;
        //   case (radius <= 50) : radius = 50; break;
        //   default             : radius = 51 ; break; //51
        // }


        let fixed = 4;
        // switch (radius) {
        //   case 5:   fixed = 6; break;
        //   case 10:  fixed = 6; break;
        //   case 25:  fixed = 1; break;
        //   case 50:  fixed = 1; break;
        //   default:  fixed = 5; break;
        // }

        request.latitude = request.latitude.toFixed(fixed);
        request.longitude = request.longitude.toFixed(fixed);
        request.radius = radius;
        // test end
      }

      return {
        request: request,
        prepare: prepare,
      }

    },

    selectMapHotel(hotelObj) {

      function openInNewTab(url) {
        var win = window.open(url, '_blank');
        win.focus();
      }

      openInNewTab(hotelObj.hotel_url);
    },
  },
  computed: {
    mapZoom() {
      let zoom = parseInt(this.$store.getters.widgetSetting('mapZoom'))
      zoom = zoom && zoom >= 13 ? zoom : 13

      return zoom;
    },
    partnerHotelsPrepared() {
      // if (this.eventStatus !== 'SUCCESS') {
      //   return [];
      // }
      // return this.eventHotels ? partnerHotelsPrepare(this.eventHotels, this.eventData) : [];
      return (this.eventData && this.eventData.hotels) ? partnerHotelsPrepare(this.eventData.hotels, this.eventData) : [];
    },
    poiPrepared() {
      return (this.eventData && this.eventData.poi) ? poiPrepare(this.eventData.poi) : [];
    },

    currency: () => Currency.getName(),
    language: () => Language.getName(),
    displayPrice() {
      return this.settings.displayPrice;
    },

    hotelListDataFiltered() {
      const params = {
        minRating: this.settings.rating,
        stars: this.settings.hotelStars,
        budget: {
          value: this.settings.budget,
          currency: this.currency
        },
        cancelation: this.settings.cancelation,
      };

      // TODO fix
      return hotelFilter(this.hotelListData, params);
      // return this.hotelListData; // for test view all
    },
    budgetType() {
      const table = {
        'Per night': 'nightly',
        'Total': 'total',
      };

      const type = this.settings.displayPrice.find(i => i.active === true);
      return type ? table[type.name] : '';
    },
  },
  mounted() {
    document.title = this.settings.labelSettings.label
    document.documentElement.setAttribute('lang',this.language)
  },
  async created() {
    this.load()
  },
};

</script>
<style lang="scss">
.vue-notification-group {
  z-index: 100001 !important;
}
</style>
